/* using node-style package resolution in a CSS file:  */
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";


.App {
  text-align: center;
  background-color: #282c34;
  padding: 2em 0 3em 0;;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.switcher {
  text-align: center;
  /* min-height: 10vh; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-width: 5vw;
  margin: 2vh 1vw;
}

.switch {
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  /* border: 1px dashed #fff; */
  /* background: #3d424e; */
  border-radius: 10px;
  /* min-height: 28vh; */
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1vw 0 0 0;
  min-width: 100%;
}

.switch :hover {
  cursor: grab;
}

.switch.active {
  background:#121c13;
  transition: all .5s;
}

.switch > .headline {
  margin: 1em 0 .2em 0;
  font-size: 2em;
  font-weight: 700;
  color: #fff;
}
.switch.active > .headline {
  color: #fff;
  /* border-bottom: 1px dotted #fff; */
  padding-bottom: .3em;
}

.switch > .desc {
  font-weight: 400;
  padding: 1em 3em;
  color: #fff;
}

.switch.active > .desc {
  color: rgb(251, 255, 0);
}

/* .MuiOutlinedInput-input {
  font-size: 2em;
} */